import "./assets/main.css";

import { VueQueryPlugin } from "@tanstack/vue-query";
import { createPinia } from "pinia";
import { createApp } from "vue";
import * as Sentry from "@sentry/vue";

// @ts-ignore
import Lara from "@/presets/lara";

import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";

import App from "./App.vue";
import router from "./router";

const app = createApp(App);

Sentry.init({
	app,
	// if this environment variable is not set, Sentry will not be initialized. This is useful for local development. DO NOT SET LOCALLY.
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [
		Sentry.browserTracingIntegration({ router }),
		Sentry.replayIntegration({
			maskAllText: false,
		}),
		Sentry.browserProfilingIntegration(),
	],
	release: import.meta.env.VITE_SENTRY_RELEASE,
	environment: import.meta.env.VITE_DEPLOYED_ENV ?? "local",
	// Tracing
	tracesSampleRate: 1.0, // Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [
		"localhost",
		"app.seedgreen.gva.local",
		"app.seedgreen.gva-dev.ai",
		"app.seedgreen.gva.ai",
	],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	// Profiling
	profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
});

app.use(router);
app.use(VueQueryPlugin);
app.use(PrimeVue, {
	unstyled: true,
	pt: Lara,
});
app.use(ToastService);
app.use(ConfirmationService);

app.directive("tooltip", Tooltip);

app.use(createPinia());
app.mount("#app");
